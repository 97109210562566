var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roles"},[_c('h3',{staticClass:"app-title"},[_vm._v(_vm._s(_vm.$t('roles')))]),(_vm.isRolesLoading)?_c('loader-component'):_vm._e(),(!_vm.isRolesLoading)?_c('div',{staticClass:"roles__content"},[_c('div',{staticClass:"roles__controls"},[_c('v-btn',{attrs:{"to":_vm.$localize({ name: 'roles.create' }),"depressed":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('btnRoleCreate'))+" ")])],1),(_vm.roles.length > 0)?_c('v-simple-table',{staticClass:"roles__table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('rolesTitle'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('rolesPermissions'))+" ")]),_c('th')])]),_c('tbody',_vm._l((_vm.roles),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',{staticClass:"v-data-table__cell-title"},[_vm._v(_vm._s(_vm.$t('rolesTitle')))]),_vm._v(" "+_vm._s(item.title)+" ")]),_c('td',{staticClass:"roles__permission"},[_c('div',{staticClass:"v-data-table__cell-title"},[_vm._v(_vm._s(_vm.$t('rolesPermissions')))]),_c('div',{staticClass:"roles__permission-list"},_vm._l((item.permissions.data),function(permission){return _c('span',{key:permission.id},[(permission.description)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"roles__permission-item",attrs:{"x-small":"","outlined":"","color":"primary"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(permission.title)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(permission.description))])]):_vm._e(),(!permission.description)?_c('v-chip',{staticClass:"roles__permission-item",attrs:{"x-small":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(permission.title)+" ")]):_vm._e()],1)}),0)]),_c('td',{staticClass:"v-data-table__td-controls roles__table-controls-wrapper"},[_c('div',{staticClass:"roles__table-controls"},[(!item.isLoading)?_c('span',[(item.can_delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-briefcase-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])])],1):_vm._e(),(item.isLoading)?_c('v-progress-circular',{staticClass:"roles__table-loader",attrs:{"indeterminate":"","color":"primary","width":2,"size":24}}):_vm._e()],1)])])}),0)]},proxy:true}],null,false,558292331)}):_vm._e(),(_vm.roles.length === 0)?_c('div',{staticClass:"roles__empty"},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }