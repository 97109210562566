import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'roles.list',
  data() {
    return {}
  },
  components: {},
  created() {
    this.fetchRoles();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      roles: 'roles/roles',
      isRolesLoading: 'roles/isRolesLoading'
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchRoles: 'roles/ROLES_REQUEST_LIST_GET',
      deleteRole: 'roles/ROLES_REQUEST_DELETE',
    }),
    ...mapMutations({
      setRole: 'roles/ROLES_DATA'
    }),
    deleteItem(role) {
      this.$set(role, 'isLoading', true);
      this.deleteRole(role.id).then(() => {
        this.$toasted.success(this.$t('rolesDeleted'));
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      }).finally(()=>{
        this.$set(role, 'isLoading', false);
      });
    },
    edit(item) {
      this.setRole(item)
      this.$router.push({name: 'roles.edit', params: {roleId: item.id}}).catch(() => {console.log();});
    }
  },
  destroyed() {},
}
